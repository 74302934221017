<template>
    <div class="overflow-hidden">
        <div class="fixed  top-0 h-screen w-screen bg-gray-500 z-50 opacity-40">
        </div>

        <div class="flex absolute top-0 justify-center w-full items-center h-screen">
            <div class="relative rounded-lg bg-white z-50 w-full max-w-xl p-6">

                <div class="text-left mb-2">
                    <h3 class="text-base font-semibold leading-6 text-gray-900 mb-4">Sign in</h3>

                    <!-- <input type="checkbox" name="loginAsGuest" id="loginAsGuest" :checked="loginAsGuest" class="accent-red-600" 
                        @click="toggleLoginAsGuest()"> -->
                    <!-- <label for="loginAsGuest" class="ml-1">Login as guest</label> -->
                </div>
                <!-- Login form -->
            
                <div v-if="!loginAsGuest">
                    <img v-if="isLogging" src="@/assets/load.svg" width="48" class="mx-auto animate-spin" />
                    <!-- <div v-if="store.visibleActionMessage" :class="store.actionMessage.type"
                        class="p-2 w-[80%] mx-auto h-10 rounded-md accoutMessageAnimation">
                        <p class="">{{ store.actionMessage.message }}</p>
                    </div>
                    <form @submit.prevent="onLogin">
                        <div class="text-left mb-4">

                            <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Username</label>
                            <div class="mt-2">
                                <input type="name" name="name" id="name" v-model="name" required autocomplete="off"
                                    class="block p-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="e.g. John" />
                            </div>
                            <div>
                                <label for="password"
                                    class="block text-sm font-medium leading-6 text-gray-900">Password</label>
                                <div class="mt-2">
                                    <input type="password" name="password" id="password" v-model="password" required
                                        class="block p-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="* * * * * *" />
                                </div>
                            </div>

                            <button type="submit" :disabled="store.isButtonDisable"
                                class="mt-4 rounded-md bg-red-600 disabled:bg-red-300 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Login</button>

                        </div>
                    </form> -->
                </div>

                <!-- Guest login form -->

                <div v-if="loginAsGuest">
                    <form @submit.prevent="onLoginAsGuest">
                        <div class="text-left mb-4">
                            <label for="nick" class="block text-sm font-medium leading-6 text-gray-900">Nickname</label>
                            <div class="">
                                <input type="text" name="nick" id="nick" v-model="nick" required autocomplete="off"
                                    class="block p-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="e.g. John" />
                                <p class="text-sm">Username: G|{{ nick }}</p>
                            </div>

                            <div class="mt-2.5">
                                <label for="gender" class="block text-sm font-medium leading-6 text-gray-900">Gender</label>
                                <select id="gender" name="gender" v-model="gender" required
                                    class="block w-full rounded-md border-0 py-2.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                    <option value="" selected disabled>Select your gender</option>
                                    <option :value="GENDER_MALE">Male </option>
                                    <option :value="GENDER_FEMALE">Female</option>
                                    <option :value="GENDER_OTHER">Others</option>
                                </select>
                            </div>
                            
                            <div class="mt-2.5">
                                <label for="date" class="block text-sm font-medium leading-6 text-gray-900">Date of Birth</label>
                                <div class="flex gap-2">
                                    <div class="grow">
                                        <label for="dobMonth" class="block text-sm font-medium leading-6 text-gray-900">Month</label>
                                        <select v-model="dobMonth" required class="block w-full rounded-md border-0 py-2.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <option value="" selected disabled>Select month</option>
                                            <option v-for="month in months" :key="month.value" :value="month.value">{{ month.text }}</option>
                                        </select>
                                    </div>
                                    <div class="grow">
                                        <label for="dobDay" class="block text-sm font-medium leading-6 text-gray-900">Day</label>
                                        <select v-model="dobDay" class="block w-full rounded-md border-0 py-2.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <option value="" selected disabled>Select day</option>
                                            <option v-for="day in 31" :key="day" :value="`${day}`.padStart(2, '0')">{{ day }}</option>
                                        </select>
                                        <!-- <input type="number" id="dobDay" v-model="dobDay" required placeholder="Day" min="1" max="31"/> -->
                                    </div>
                                    <div class="grow">
                                        <label for="dobYear" class="block text-sm font-medium leading-6 text-gray-900">Year</label>
                                        <select v-model="dobYear" class="block w-full rounded-md border-0 py-2.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <option value="" disabled>Select year</option>
                                            <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="flex items-center mt-2.5">
                                <label class="ml-1">
                                  <input type="checkbox" name="agreeTerms" :checked="agreeTerms" class="accent-red-600" @click="toggleAcceptTerms()">
                                  I accept the <a href="https://www.123freechat.com/legal/terms/" class="text-main">terms of use</a>
                                </label>
                            </div>

                            <button type="submit" id="guestLoginButton" :disabled="store.isButtonDisable"
                                class="w-full mt-4 rounded-md bg-orange-600 disabled:bg-orange-300 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Connect</button>
                        </div>
                    </form>
                </div>

                <div v-if="store.visibleActionMessage" :class="store.actionMessage.type"
                    class="p-2 w-[100%] mx-auto h-10 rounded-md accoutMessageAnimation">
                    <p class="">{{ store.actionMessage.message }}</p>
                </div>

                <!-- <hr>
                <div class=" text-right">
                    <button @click="openRegister()" :disabled="store.isButtonDisable"
                        class="mt-4 rounded-md bg-red-600 disabled:bg-red-300 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Create
                        Account</button>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { login } from '@/irc/client.js';
import { useMainStore } from "@/mainStore";
import axios from 'axios';
import { GENDER_FEMALE, GENDER_MALE, GENDER_OTHER, months } from '@/constants';

const store = useMainStore();

const onLoginAsGuest = () => {
    if (!agreeTerms.value) {
        const message = {
            message: "Please agree to the terms of use.",
            type: 'warning'
        };
        store.storeActionMessage(message);
        store.showActionMessage();
        setTimeout(() => {
            store.hideActionMessage();
        }, 3000);
        return;
    }

    store.disableButton()
    let today = new Date();
    const age = today.getFullYear() - new Date(combinedDOB.value).getFullYear(); // Use combinedDOB value for birth date.
    if (age >= 18 & age <= 90) {
        document.getElementById("guestLoginButton").disabled = true;
        let message = {
            message: 'Connecting to server...',
            type: 'success',
        }
        setTimeout(() => {
            store.hideActionMessage();
            store.enableButton()
            store.setIsLoggedIn(true)
        }, 3000);
        store.storeActionMessage(message)
        store.showActionMessage();
        login("G|" + nick.value, '', getUserAlias(gender.value, new Date(combinedDOB.value)), website);
        store.socket.emit('login', { user: "G|" + nick.value, gender: gender.value, dob: combinedDOB.value, type: 'guest' }); // Removed country field.
        store.storeUserName("G|" + nick.value)
        store.storeGender(gender.value)
        store.setIsLoggedIn(true)
        
        if (room) {
            store.setActiveRoom("#" + room);
        }
        store.socket.on('userList', (userList) => {
            store.cleanSocketUserList();
            userList.forEach(user => {
                store.setSocketUserList(user);

            });
        });
        store.socket.on('sentMessageHistory', (messages) => {
            messages?.forEach(message => {
                store.storeSocketMessage(message);

            });
        });
    } else {
        if (age < 18) {
            const message = {
                message: 'You are not 18 or older',
                type: 'warning'
            };
            store.enableButton()
            store.storeActionMessage(message);
            store.showActionMessage();
            setTimeout(() => {
                store.hideActionMessage();
            }, 3000);
        } else {
            const message = {
                message: 'You are 90+',
                type: 'warning'
            };
            store.enableButton()
            store.storeActionMessage(message);
            store.showActionMessage();
            setTimeout(() => {
                store.hideActionMessage();
            }, 3000);
        }
    }
}

const onLogin = () => {
    // const loginData = {
    //     name: name.value,
    //     password: password.value,
    // };
    const loginData = {
        sessionId: sessionId.value
    };
    
    console.log("scah", store.config.apiHost)
    axios.post(`${store.config.apiHost}/login`, loginData)
        .then(response => {
            store.disableButton()
            const data = response.data.result;
            login(data.name, data.password, getUserAlias(data.gender, new Date(data.dob)), website);
            let loginUserData = {
                user: data.name,
                gender: data.gender,
                dob: data.dob,
                country: data.country,
                type: data.type,
                profileId: data.profileId,
                profilePhoto: data.profilePhoto,
            }
            store.socket.emit('login', loginUserData);
            store.storeUserName(data.name)
            store.storeGender(data.gender)
            store.storeActionMessage(response.data)
            store.showActionMessage();
            setTimeout(() => {
                store.hideActionMessage();
            }, 3000);

            if (room) {
                store.setActiveRoom("#" + room);
            }

            store.socket.on('userList', (userList) => {
                store.cleanSocketUserList();
                userList.forEach(user => {
                    store.setSocketUserList(user);

                });
            });
            store.socket.emit('sentMessageHistory');
            store.socket.on('messageHistory', (messages) => {
                console.log(messages);
                messages.forEach(message => {
                    store.storeSocketMessage(message);

                });
            });
        })
        .catch(error => {
            if (error.response && error.response.data) {
                store.storeActionMessage(error.response.data);
                store.showActionMessage();
                setTimeout(() => {
                    store.hideActionMessage();
                }, 3000);
            }

            loginAsGuest.value = true;
        }).finally(() => {
            store.enableButton();
            isLogging.value = false;
        });

}

// const openRegister = () => {
//     store.hideLoginModal();
//     store.showRegisterModal();
// }

const parseDOB = (dob) => {
  const dobParts = dob?.split(/[*\-/]/);
  const isValidMonth = dobParts && dobParts[0] && parseInt(dobParts[0], 10) >= 1 && parseInt(dobParts[0], 10) <= 12;
  const isValidDay = dobParts && dobParts[1] && parseInt(dobParts[1], 10) >= 1 && parseInt(dobParts[1], 10) <= 31;
  const isValidYear = dobParts && dobParts[2] && parseInt(dobParts[2], 10) >= startYear && parseInt(dobParts[2], 10) <= endYear;

  return [
    isValidMonth ? dobParts[0] : "",
    isValidDay ? dobParts[1] : "",
    isValidYear ? dobParts[2] : ""
  ];
};

const params = new URLSearchParams(window.location.search);

//
const sessionId = ref(params.get('session') || "");
const isLogging = ref(false);
// Guest login data
// const name = ref(params.get('name') || "");
// const password = ref("");

const nick = ref(params.get('nickname') || "");
const gender = ref(params.get('gender') || "");
const loginAsGuest = ref(params.get('guestcheck') === 'true')
const agreeTerms = ref(!!params.get('nickname'))
const room = params.get('channel')
const website = params.get('website');

// Fields for dob.
const dobStr = parseDOB(params.get('dob'));
const [dobMonth, dobDay, dobYear] = dobStr.map(part => ref(part));

const startYear = 1940;
const endYear = 2024
const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i).reverse();

const combinedDOB = computed(() => {
  if (dobMonth.value && dobDay.value && dobYear.value) {
    return `${dobYear.value}-${dobMonth.value.toString().padStart(2, '0')}-${dobDay.value.toString().padStart(2, '0')}`;
  }
  return '';
});

const getUserAlias = (gender, dob) => {
  const _gender = gender === GENDER_MALE ? "M" : gender === GENDER_FEMALE ? "F" : "O"
  const currentDate = new Date();
  // Calculate the difference in years
  let age = currentDate.getFullYear() - dob.getFullYear();
  // Check if birthday hasn't occurred this year
  const monthDiff = currentDate.getMonth() - dob.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < dob.getDate())) {
    age--;
  }
  
  return `${_gender}-${age}`;
};

// const toggleLoginAsGuest = () => {
//     loginAsGuest.value = !loginAsGuest.value
// }

const toggleAcceptTerms = () => {
    agreeTerms.value = !agreeTerms.value
}

// Auto login
onMounted(() => {
    if (store.isForceLogout) {
        loginAsGuest.value = true;
        return;
    }
    if (loginAsGuest.value && nick.value && gender.value && dobMonth.value && dobDay.value && dobYear.value) {
        onLoginAsGuest();
    } else if (!loginAsGuest.value) {
        if (sessionId.value?.length > 0) {
            isLogging.value = true;
            onLogin();
        } else {
            loginAsGuest.value = true;
        }
    }
});
</script>
