<script setup>
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { useMainStore } from '@/mainStore';
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';

const store = useMainStore();
const FontModalRef = ref(null);


const textColors = [
    { id: 0, color: 'bg-[#fff]', value: 'text-[#fff]' },
    { id: 1, color: 'bg-[#000]', value: 'text-[#000]' },
    { id: 2, color: 'bg-[#00007F]', value: 'text-[#00007F]' },
    { id: 3, color: 'bg-[#009300]', value: 'text-[#009300]' },
    { id: 4, color: 'bg-[#FF0000]', value: 'text-[#FF0000]' },
    { id: 5, color: 'bg-[#7F0000]', value: 'text-[#7F0000]' },
    { id: 6, color: 'bg-[#9C009C]', value: 'text-[#9C009C]' },
    { id: 7, color: 'bg-[#FC7F00]', value: 'text-[#FC7F00]' },
    { id: 8, color: 'bg-[#FFFF00]', value: 'text-[#FFFF00]' },
    { id: 9, color: 'bg-[#00FC00]', value: 'text-[#00FC00]' },
    { id: 10, color: 'bg-[#009393]', value: 'text-[#009393]' },
    { id: 11, color: 'bg-[#00FFFF]', value: 'text-[#00FFFF]' },
    { id: 12, color: 'bg-[#0000FC]', value: 'text-[#0000FC]' },
    { id: 13, color: 'bg-[#FF00FF]', value: 'text-[#FF00FF]' },
    { id: 14, color: 'bg-[#7F7F7F]', value: 'text-[#7F7F7F]' },
    { id: 15, color: 'bg-[#D2D2D2]', value: 'text-[#D2D2D2]' }
]



onClickOutside(FontModalRef, store.hideFontModal);

</script>


<template>
    <div class="bg-gray-400/50 absolute top-0 h-screen w-screen flex flex-wrap justify-center overflow-y-auto" id="modal-container z-10">
        <div class="w-full sm:w-[30rem] bg-white my-10 h-fit rounded-md p-2 text-black" ref="FontModalRef" id="modal">
            <div class="flex justify-between items-center px-4 py-5">
                <p class="text-2xl">FontDialog</p>
                <XMarkIcon class="h-6 w-6 text-gray-500 hover:text-gray-800 cursor-pointer"
                    @click="store.hideFontModal" />
            </div>
            <hr>
            <div class="text-left ml-6 my-4">
                <div class="my-2">
                    <input type="checkbox" name="bold" id="bold" :checked="store.boldMessage" @click="store.setMessageStyleBold">
                    <label for="bold" class="font-bold ml-2">Bold</label>
                </div>
                <div class="my-2">
                    <input type="checkbox" name="underline" id="underline" :checked="store.underlineMessage" @click="store.setMessageStyleUnderline">
                    <label for="underline" class="underline ml-2">Underline</label>
                </div>
                <div class="my-2">
                    <input type="checkbox" name="italic" id="italic" :checked="store.italicMessage" @click="store.setMessageStyleItalic">
                    <label for="italic" class="italic ml-2">Italic</label>
                </div>
                <div class="my-2">
                    <input type="checkbox" name="activate-color" id="activate-color" :checked="store.isColorActive" @click="store.toggleColorActive">
                    <label for="activate-color" class="ml-2">Activate Color</label>
                </div>
            </div>
            <p class="text-left sm:mx-6">Background color</p>
            <div class="flex flex-wrap items-center min:h-10 ml-1 sm:ml-6">
                <div class="m-0.5 md:m-1 mb-4 cursor-pointer" v-for="color in textColors" :key="color.id">
                    <div :class="[color.color == store.BackgroundColor ? `${color.color} h-6 w-6 border border-black ` : `${color.color} h-4 w-4 hover:h-6 hover:w-6 hover:border hover:border-black`]" @click="store.setBackgroundColor(color.color, color.id)"></div>
                </div>
            </div>
            <p class="text-left sm:mx-6">Foreground/text color</p>
            <div class="flex flex-wrap items-center min:h-10 sm:ml-6">
                <div class="m-0.5 md:m-1 mb-4 cursor-pointer" v-for="color in textColors" :key="color.id">
                    <div :class="[color.value == store.textColor ? `${color.color} h-6 w-6 border border-black ` : `${color.color} h-4 w-4 hover:h-6 hover:w-6 hover:border hover:border-black`]" @click="store.setTextColor(color.value, color.id)"></div>
                </div>
            </div>

            <hr>
            <div class="py-4">
                <button class="bg-custom-secondary w-full py-2 rounded-md text-white" @click="store.hideFontModal">Close</button>
            </div>
        </div>
    </div>
</template>
