<template>
    <tr>
        <td class="w-20">
            <button @click="joinRoom" class="w-full hover:ring-1 hover:ring-blur-600 justify-center my-0.5 text-center bg-custom-secondary rounded-md p-1.5 px-2 text-white flex gap-1 items-center">
                <ArrowLeftOnRectangleIcon class="h-5 w-5"/>
                {{ room.name }}
            </button>
        </td>
        <td class="text-center w-12">{{ room.count }}</td>
        <td class="w-64 text-left max-w-[10rem] md:max-w-sm">
            <span v-html="parseIRCColours(room.topic)"></span>
        </td>
    </tr>
</template>


<script setup>
import { join } from "@/irc/client";
import { useMainStore } from "@/mainStore";
import { ArrowLeftOnRectangleIcon } from "@heroicons/vue/24/solid";
import { defineProps } from "vue";
import { parseIRCColours } from "@/helpers";
const store = useMainStore();

const joinRoom = () => {
    join(props.room.name)
    store.hideRoomList();
    store.showMessageRoom();
    store.setActiveRoom(props.room.name);
    
    let user = {
        name: props.room.name,
        count: props.room.count,
        topic: props.room.topic,
        type: "room"
    };
    if (!store.activeRoomList.find(room => room.name == user.name)) {
        store.storeActiveRoomList(user);
    }
    store.tabNotification[props.room.name] = [];
    setTimeout(() => {
        store.tabNotification[props.room.name] = [];
    }, 1000);
    store.hideWhisperInput()
}

const props = defineProps({
    room: Object
});
</script>
