<template>
    <div class="w-screen h-full absolute sm:relative bg-gray-500/50 sm:min-w-[13rem] sm:max-w-[13rem] z-10" id="side-bar">
        <div class="w-[13rem] h-full flex flex-col min-h-0 bg-white" :class="sideBarPos" ref="sideBarRef">
            <div class="h-[90px]">
                <div class="bg-white">
                    <p>{{ store.activeRoom }}</p>
                </div>

                <div class="flex">
                    <button class="border p-1 px-9 basis-full" v-if="store.sortUserListAtoZ && !store.sortUserByGender"
                        @click="store.setUserSort">a - z</button>
                    <button class="border p-1 px-9 basis-full" v-if="!store.sortUserListAtoZ && !store.sortUserByGender"
                        @click="store.setUserSort">z - a</button>
                    <button class="border p-1 px-9 basis-full" v-if="store.sortUserByGender && store.sortUserListAtoZ"
                        @click="store.setUserSort">f - m</button>
                    <button class="border p-1 px-9 basis-full" v-if="store.sortUserByGender && !store.sortUserListAtoZ"
                        @click="store.setUserSort">m - f</button>

                    <button class="border p-1 px-1 basis-2" @click="store.toggleUserListSort">
                        <PlayIcon :class="[store.sortUserListAtoZ ? 'h-4 w-4 text-black rotate-90' : 'h-4 w-4 text-black -rotate-90 z-0']" />
                    </button>
                </div>

                <div class="flex mt-1">
                    <input type="text" v-model="searchText" class="border-2 border-gray-400 w-44 rounded">
                    <button class="bg-custom-secondary rounded px-2 py-1" @click="clearSearchInput">
                        <XMarkIcon class="h-4 w-4 text-white " />
                    </button>
                </div>
            </div>

            <div class="flex flex-col flex-1 min-h-0 rounded-sm mt-1 border-x border-gray-300 p-1">
                <div class="overflow-y-auto" id="userlist">
                    <Popper arrow v-for="user in filteredUsers" :modifier="modifiers" placement="left-start" :key="user" offset-distance="0" class="w-full odd:bg-gray-100 rounded-md">
                        <div class="py-1 cursor-pointer">
                            <div class="flex justify-between items-center">
                                <div class="px-1 flex items-center">
                                    <img @click="store.toggleUserOption(user)" 
                                        class="h-6 w-6 rounded-full border border-gray-700"
                                        v-if="store.enableUserListAvatar"
                                        :src="getImageSource(user)" 
                                        alt="User avatar">

                                    <VideoCameraIcon @click="(e) => {e.stopPropagation(); joinBroadcast(user.userName) }"
                                        v-if="store.broadcastingUsers.findIndex(buser => buser == user.userName || '@'+ buser === user.userName || '+'+buser === user.userName) > -1"
                                        class="ml-1 w-6 h-6" />

                                    <p @click="store.toggleUserOption(user)" class="ml-2" v-if="user.userName.length <= 10">{{ getUsername(user.userName) }}</p>
                                    <p @click="store.toggleUserOption(user)" class="ml-2" v-else>{{ getUsername(user.userName).substring(0, 10) + "..." }}</p>
                                    <img class="h-4 w-4 ml-1"
                                        v-if="user.userName.startsWith('@')"
                                        src="/img/mod.svg" 
                                        alt="Moderator">
                                    <img class="h-4 w-4 ml-1"
                                        v-if="user.userName.startsWith('+')"
                                        src="/img/voice.svg" 
                                        alt="VIP">
                                </div>
                                <div v-if="user.gender == GENDER_MALE" class="mr-2">
                                    <svg style="color: rgb(6, 89, 238);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gender-male mr-2"
                                        viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8z" fill="#0659ee"></path>
                                    </svg>
                                </div>

                                <div v-if="user.gender == GENDER_FEMALE" class="mr-2">
                                    <svg style="color: rgb(236, 72, 153);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gender-female mr-2" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"  d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5z" fill="#ec4899"></path>
                                    </svg>
                                </div>

                                <div v-if="user.gender == GENDER_OTHER" class="mr-2">
                                    <svg style="color: red" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-question mr-2" viewBox="0 0 16 16">
                                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" fill="red"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <template #content>
                            <div class="w-[8rem] block bg-white p-1 border border-gray-400 cursor-default">

                                <div class="text-left text-xs py-1">
                                    <p>{{ user.userName }}</p>
                                </div>
                                <div>
                                    <ul class="z-10">
                                        <hr class="border-t-black">
                                        <li @click="privateMessage(user)" v-if="!checkNameSameWithModerator(store.userName, user.userName)" class="text-left text-blue-400 hover:underline text-xs my-1 cursor-pointer w-fit">
                                            Private message
                                        </li>
                                        <li @click="onWhisper(user)" class="text-left text-blue-400 hover:underline text-xs my-1 cursor-pointer w-fit">
                                            Whisper
                                        </li>
                                        <li v-if="user.profileId" @click="openProfile(user)" class="text-left text-blue-400 hover:underline text-xs my-1 cursor-pointer w-fit">
                                            Profile
                                        </li>
                                        <li @click="store.showReportModal(user)" class="text-left text-blue-400 hover:underline text-xs my-1 cursor-pointer w-fit">
                                            Report
                                        </li>
                                        <li @click="store.showKickModal(user)" v-if="isMod" class="text-left text-blue-400 hover:underline text-xs my-1 cursor-pointer w-fit">
                                            Kick
                                        </li>
                                        <li @click="onBan(user)" v-if="isMod" class="text-left text-blue-400 hover:underline text-xs my-1 cursor-pointer w-fit">
                                            Ban
                                        </li>
                                        <li @click="onGiveMod(user)" v-if="isMod" class="text-left text-blue-400 hover:underline text-xs my-1 cursor-pointer w-fit">
                                            Give mod
                                        </li>
                                        <li @click="onTakeMod(user)" v-if="isMod" class="text-left text-blue-400 hover:underline text-xs my-1 cursor-pointer w-fit">
                                            Take mod
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </template>
                    </Popper>
                </div>
                <div class="flex justify-between items-center py-2 bg-white block sm:hidden mt-auto">
                    <p class="font-bold ml-1">Users: {{ filteredUsers.length }}</p>
                    <button class="bg-custom-secondary p-1.5 rounded-md mr-3" @click="store.hideSideBar">
                        <XMarkIcon class="h-6 w-6 text-white" />
                    </button>
                </div>
                <hr>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useMainStore } from '@/mainStore';
import { onClickOutside } from '@vueuse/core';
import { computed, ref } from 'vue';
import Popper from "vue3-popper"; 
import { PlayIcon, VideoCameraIcon, XMarkIcon } from '@heroicons/vue/24/solid';
import { joinBroadcast } from '@/irc/broadcaster';
import { checkNameSameWithModerator, isModUser, isModerator } from '@/helpers';
import { GENDER_FEMALE, GENDER_MALE, GENDER_OTHER } from '@/constants';
import { sendGiveMod, sendTakeMod, sendWhois } from '@/irc/client';

const store = useMainStore();
const searchText = ref("");
const userOptionModalRef = ref(null);
onClickOutside(userOptionModalRef, store.toggleUserOption);

const sideBarRef = ref(null);
onClickOutside(sideBarRef, () => {
    if (window.innerWidth <= 640) store.hideSideBar();
});

const modifiers = [
  {
    name: 'preventOverflow',
    options: {
      boundary: 'viewport', 
      padding: 10, // Optional padding from the viewport edges
    },
  },
  {
    name: 'flip',
    options: {
      enabled: true, // Allow the popper to flip if necessary
    },
  },
]

const privateMessage = (users) => {
    let userName = users.userName.replace(/^@/, '');
    let user = {
        name: userName,
        gender: users.gender,
        type: "user"
    };
    store.tabNotification[userName] = [];
    store.storeActiveRoomList(user);
    store.setActiveRoom(userName);
};

const openProfile = (user) => {
    // store.toggleUserOption();
    // store.showProfileModal();
    // store.storeUserProfileData(user);
    window.open("https://www.123freechat.com/forums/members/" + user.profileId).focus();

};

const onWhisper = (user) => {
    store.messageValue = '/whisper ' + user.userName + " ";
    store.toggleUserOption();
    store.storeWhisperUser(user);
};

const onBan = (user) => {
    store.addToBanList(user.userName, store.activeRoom)
    sendWhois(user.userName);
    store.toggleUserOption();
};

const onGiveMod = (user) => {
    sendGiveMod(store.activeRoom, user.userName)
    store.toggleUserOption();
};

const onTakeMod = (user) => {
    sendTakeMod(store.activeRoom, user.userName)
    store.toggleUserOption();
};

const getUsername = (name) => {
    if (name.startsWith('@') || name.startsWith('+'))
        return name.slice(1);
    return name
}

const isMod = computed(() => isModUser(store));
const sideBarPos = computed(() => store.sideBarInLeft ? "mr-auto" : "ml-auto");

const filteredUsers = computed(() => {
    let items = store.sidebarUserList[store.activeRoom] || [];

    const uniqueNames = new Set();

    const filtered = items
        .filter(item => {
            const lowerCaseName = item.userName.toLowerCase();
            if (uniqueNames.has(lowerCaseName)) {
                return false;
            } else {
                uniqueNames.add(lowerCaseName);
                return lowerCaseName.includes(searchText.value.toLowerCase());
            }
        })
        .sort((a, b) => {
            if (isModerator(a.userName) && !isModerator(b.userName)) {
                return -1;
            } else if (!isModerator(a.userName) && isModerator(b.userName)) {
                return 1;
            }
            if (store.sortUserByGender) {
                if (store.sortUserListAtoZ) {
                    return a.gender.localeCompare(b.gender);
                } else {
                    return b.gender.localeCompare(a.gender);
                }
            } else {
                if (store.sortUserListAtoZ) {
                    return a.userName.localeCompare(b.userName);
                } else {
                    return b.userName.localeCompare(a.userName);
                }
            }
        });

    store.setListLength(filtered.length);
    return filtered;
});

const getImageSource = (user) => {
    if (user.profilePhoto) {
        return new URL(user.profilePhoto, import.meta.url).href;
    }
    if (user.gender === GENDER_MALE) {
        return new URL('../../public/img/male.png', import.meta.url).href;
    }
    if (user.gender === GENDER_FEMALE) {
        return new URL('../../public/img/female.png', import.meta.url).href;
    }

    return new URL('../../public/img/other.png', import.meta.url).href;
};

const clearSearchInput = () => {
    searchText.value = "";
};
</script>
