/* eslint-disable no-control-regex */
/* eslint-disable no-unused-vars */
import { store } from "@/store";
import { defaultForegroundId, defaultBackgroundId } from "@/mainStore";
import event from "./event";
import replies from "./replies";
import { useMainStore } from "@/mainStore";
import { MSG_TYPE, QUERY_EMBED } from "@/constants";

export function log(...params) {
    console.log(...params);
}

export function login(nick, pass, userAlias, site) {
    store.commit("setNick", nick);

    if (store.getters.irc) {
        console.log("Closing existing connection")
        store.getters.irc.close();
    }

    connect(() => {
        send(`NICK ${nick}`);
        send(`PASS ${pass}`)
        send(`USER ${userAlias} 0 * :${site || "123FreeChat.com"}`);
    });
}

export function join(room) {
    const store = useMainStore();
    send(`JOIN ${room}`);
    store.setActiveRoom(room);    
    store.tabNotification[room] = [];
    
    if (store.notificationSound) {
        const joinAudio = new Audio("/audio/join.wav");
        joinAudio.play();
    }
}

export function quit(room) {
    const store = useMainStore();
    store.clearRoomMessages(room);
    send(`PART ${room}`);
    
    if (store.notificationSound) {
        const quitAudio = new Audio("/audio/quit.wav");
        quitAudio.play();
    }
}

export function logout(userName) {
    const store = useMainStore();
    send(`QUIT ${userName}`)
    store.hideMessageRoom()
    store.hideMenuList()
    store.enableButton()
    store.messageHistory = {}
    store.activeRoomList = []
    store.socketUserList = []
    store.showLoginModal()
    store.socket.disconnect();
    store.setForceLogout();
}

// color codes
const ccBold = String.fromCharCode(0x02)
const ccItalic = String.fromCharCode(0x1D)
const ccUnderline = String.fromCharCode(0x1F)
const ccStrikethrough = String.fromCharCode(0x16)
const ccColor = String.fromCharCode(0x03)


export function sendMessage(type, message, room) {
    const store = useMainStore();
    let today = new Date();
    let time = today.getHours() + ":" + today.getMinutes();
    let _message = ""
    if(store.messageStyleBold){
      _message += ccBold
    }
    if(store.messageStyleItalic){
      _message += ccItalic
    }
    if(store.messageStyleUnderline){
      _message += ccUnderline
    }
    if(store.messageStyleStrikethrough){
      _message += ccStrikethrough
    }
    if(store.textColorId !== defaultForegroundId || store.BackgroundColorId !== defaultBackgroundId){
      _message += ccColor + `${store.textColorId || defaultForegroundId},${store.BackgroundColorId || defaultBackgroundId}`
    }
    _message += message 

    if (type === MSG_TYPE.GIF) {
        let url = new URL(_message);
        url.searchParams.set(QUERY_EMBED, 'true');
        _message = url.toString();
    }
    let cmd = `PRIVMSG ${room}${store.recipient.length > 0 ? ` @:${store.recipient}` : ''} ${_message}`
    send(cmd);

    let chatMessage = {
        userName: store.userName,
        message: _message,
        room: room,
        time: time,
        type: type,
        recipient: store.recipient,
        style: store.BackgroundColor + " " + store.textColor + " " + store.messageStyleBold + " " + store.messageStyleUnderline,
    };

    store.addMessage(chatMessage);
}

export function sendAction(message, room, user) {
    const store = useMainStore();
    let today = new Date();
    let time = today.getHours() + ":" + today.getMinutes();
    let cmd = `PRIVMSG ${room} :\x01ACTION ${message}\x01`;
    send(cmd);

    let chatMessage = {
        userName: user,
        message: message,
        room: store.activeRoom,
        style: 'font-bold italic',
        time: time,
        type: MSG_TYPE.ACTION,
        recipient: ''
    };
    store.addMessage(chatMessage);
}

export function sendNotice(message, user) {
    const store = useMainStore();
    let today = new Date();
    let time = today.getHours() + ":" + today.getMinutes();
    send(`NOTICE ${user} :${message} `);
    let chatMessage = {
        direct: "to",
        userName: user,
        message: message,
        room: store.activeRoom,
        time: time,
        style: "font-bold italic",
        type: MSG_TYPE.NOTICE,
    };
    store.addMessage(chatMessage);
}

export function sendWhisper(message, user) {
    const store = useMainStore();
    let today = new Date();
    let time = today.getHours() + ":" + today.getMinutes();
    send(`WHISPER ${user} :${message} `);
    let chatMessage = {
        direct: "to",
        userName: user,
        message: message,
        room: store.activeRoom,
        time: time,
        style: "font-bold italic",
        type: MSG_TYPE.WHISPER,
    };
    store.addMessage(chatMessage);
}

export function sendReport(message) {
    send(`PRIVMSG Bristol ${message}`)
}

export function sendKick(channel, nickname, reason) {
    send(`KICK ${channel} ${nickname} ${reason}`)
}

export function sendWhois(nickname) {
    send(`WHOIS ${nickname}`)
}

export function sendBan(channel, nickname) {
    send(`MODE ${channel} +b ${nickname}`)
}

export function sendUnBan(channel, nickname) {
    send(`MODE ${channel} -b ${nickname}`)
}

export function sendGiveMod(channel, nickname) {
    send(`MODE ${channel} +o ${nickname}`)
}

export function sendTakeMod(channel, nickname) {
    send(`MODE ${channel} -o ${nickname}`)
}

export function sendNicknameForGuest(nickname) {
    send(`NICK G|${nickname}`)
}

export function identify(password) {
    send(`IDENTIFY ${store.getters.nick} ${password}`);
}

export function send(command) {
    console.log({send: command})
    store.getters.irc.send(command);
}

const devWsUrl = 'ws://localhost:3030/socket.io/?EIO=4&transport=websocket'
function connect(callback) {
    let irc;
    log("Creating IRC connection over WebSocket");
    irc = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL || (process.env.NODE_ENV === 'development' && devWsUrl) || undefined);

    irc.onopen = () => { callback(); onopen(); };
    irc.onmessage = onmessage;
    irc.onclose = onclose;

    store.commit("setIrc", irc);
}

function onopen() {
    log("IRC connection established");
}

function onmessage(e) {
    log("IN:", e.data);
    let message = e.data;
    let eventID = getEventID(message);

    event.emit(eventID, message);
}

function getEventID(message) {
    console.log({message})
    let messageParts = message.split(" ");
    let command = messageParts[1];
    let sender = messageParts[0].split("!")[0];

    if (sender == ":NickServ" && message.indexOf("/msg NickServ IDENTIFY") > 0) {
        command = replies.RPL_PASSWORDNEEDED;
    }
    else if (sender == ":NickServ" && message.indexOf("Invalid password for") > 0) {
        command = replies.RPL_INVALIDPASSWORD;
    }
    else if (sender == ":NickServ" && message.indexOf("You are now identified") > 0) {
        command = replies.RPL_LOGGEDIN;
    }
    else if (message.indexOf("PING") === 0) {
        command = replies.RPL_PING
    }

    log("Event: ", command);

    return command;
}

function onclose() {
    log("IRC connection closed");
}
