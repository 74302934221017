<template>
    <div class="flex items-center w-[100vw] h-[50px] z-20 bg-gray-200">

        <div class="flex">
            <button class="bg-custom-secondary p-1.5 rounded-sm text-white mx-0.5" @click="store.showGifModal()" id="gif-icon-button">
                <GifIcon class="h-6 w-6" />
            </button>
            <button class="bg-custom-secondary p-1.5 rounded-sm text-white mx-0.5" @click="importFile">
                <CameraIcon class="h-6 w-6" />
            </button>
            <button class="bg-custom-secondary p-1.5 rounded-sm text-white mx-0.5" @click="store.toggleEmojiSelector()">
                <FaceSmileIcon class="h-6 w-6" />
            </button>
        </div>

        <!-- Regular message form -->
        <div class="relative w-full">
            <form @submit.prevent="onSendMessage">
                <div class="flex items-center">
                    <span class="" v-if="store.recipient.length > 0">{{ store.recipient }}:</span>
            <input 
                type="text" 
                id="message" 
                name="message inline" 
                ref="messageInputRef"
                :value="store.messageValue"
                @input="handleChangeText"
                class="w-full py-1.5 rounded-sm border px-2" 
                placeholder="Type a message" 
                autocomplete="off"
                @keydown.ctrl.up="handleCtrlUp" 
                @keydown.ctrl.down="handleCtrlDown" 
                @keydown.ctrl.left="handleCtrlLeft" 
                @keydown.ctrl.right="handleCtrlRight" 
                @keydown.backspace="handleBackspace"
                @keydown.tab="handleTabComplete"
                >
                </div>
                <button type="submit" class="hidden">send</button>
            </form>
            <div class="absolute bottom-16 left-2">
                <CommandPicker @choose="handleChooseCommand" />
            </div>
        </div>

        <div class="mx-1" ref="broadcastOptionsModal">
            <div class="flex">
                <div v-if="store.visibleBroadcastOption" class="absolute bottom-16 right-4 rounded-xl shadow-popup bg-white p-2">
                    <p>Publish..</p>
                    <hr class="border-t-black">
                    <div class="flex flex-col gap-2 py-2">
                        <button class="bg-custom-secondary rounded-md p-2 flex justify-center items-center gap-1 text-white item-center" @click="setupBroadCasting({ video: false, audio: true })">
                            <span>
                                <SpeakerWaveIcon class="h-6 w-6" />
                            </span>
                            <span>Audio Only</span>
                        </button>
                        <button class="bg-custom-secondary rounded-md p-2 flex justify-center items-center gap-1 text-white item-center" @click="setupBroadCasting({ video: true, audio: false })">
                            <span>
                                <VideoCameraIcon class="h-6 w-6" />
                            </span>
                            <span>Video Only</span>
                        </button>
                        <button class="bg-custom-secondary rounded-md p-2 flex justify-center items-center gap-1 text-white item-center" @click="setupBroadCasting({ video: true, audio: true })">
                            <span>
                                <VideoCameraIcon class="h-6 w-6" />
                            </span> <span>
                                <SpeakerWaveIcon class="h-6 w-6" />
                            </span>
                            <span>Audio+Video</span>
                        </button>
                        <button class="bg-custom-secondary rounded-md p-2 flex justify-center items-center gap-1 text-white item-center" @click="store.toggleBroadcastOption()">
                            <span>
                                <NoSymbolIcon class="h-6 w-6" />
                            </span>
                            <span>Cancel</span>
                        </button>
                    </div>
                </div>
                <button class="bg-custom-secondary p-1.5 rounded-sm text-white mx-0.5 mr-1 hidden"
                    @click="startBroadcasting"></button>
                <button class="bg-custom-secondary p-1.5 rounded-sm text-white mx-0.5 mr-1" @click="store.toggleBroadcastOption()">
                    <VideoCameraIcon class="h-6 w-6" />
                </button>
                <NavButton v-if="store.sideBar" @click="store.hideSideBar" class="rounded-sm bg-custom-secondary">
                    <UserIcon class="h-6 w-6 text-white" />
                    <svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-arrows-angle-contract" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M.172 15.828a.5.5 0 0 0 .707 0l4.096-4.096V14.5a.5.5 0 1 0 1 0v-3.975a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1h2.768L.172 15.121a.5.5 0 0 0 0 .707zM15.828.172a.5.5 0 0 0-.707 0l-4.096 4.096V1.5a.5.5 0 1 0-1 0v3.975a.5.5 0 0 0 .5.5H14.5a.5.5 0 0 0 0-1h-2.768L15.828.879a.5.5 0 0 0 0-.707z"
                            fill="white"></path>
                    </svg>
                </NavButton>
                <NavButton v-if="!store.sideBar" @click="store.showSideBar" class="bg-custom-secondary">
                    <UserIcon class="h-6 w-6 text-white" />
                    <UserIcon class="h-6 w-6 text-white" />
                </NavButton>
            </div>
        </div>
    </div>
    <div v-if="store.emojiModal" class="absolute bottom-16 left-5" ref="emojiPickerModal">
        <EmojiPicker :native="true" @select="onSelectEmoji" />
    </div>
    <div class="absolute bottom-10 hidden">
        <form @submit.prevent="sendImage()" id="imgUploaderForm" class="">
            <input type="file" name="imgFile" id="imgFile" accept="image/*" :v-model="image">
            <button type="submit">Send</button>
        </form>
    </div>
    <div v-if="store.visibleSendPhotoModal"
        class=" absolute top-0 left-0 h-screen w-screen flex justify-center overflow-y-auto z-20 bg-gray-400/50"
        id="modal-container">
        <div class="bg-white p-4 rounded-md h-fit  my-40" ref="profilePhotoRef" id="profileModal">
            <div class="flex justify-between items-center py-2 px-3">
                <p>Send Image</p>
                <XMarkIcon class="h-6 w-6 text-gray-700 cursor-pointer" @click="hideImageSender" />
            </div>

            <hr>

            <div class="m-2">
                <div v-if="store.messageImageUrl">
                    <img :src="`${store.messageImageUrl}`" alt="" class="h-[400px] w-[[400px]]">
                </div>
            </div>

            <hr>

            <div class="my-4 text-right">
                <button class="py-2 px-6 rounded-md bg-custom-secondary mx-2 text-white" :class="{ 'opacity-50': isSending }" @click="sendImage" :disabled="isSending" >{{ isSending ? 'Uploading...' : 'Send' }}</button>
                <button class="py-2 px-4 rounded-md bg-gray-200 mx-2 text-gray" @click="hideImageSender" :disabled="isSending" >Cancel</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import axios from 'axios';
import { ref } from 'vue';
import { sendMessage, sendWhisper, join, sendAction, logout, quit, sendUnBan, sendKick, sendNotice, sendWhois, sendNicknameForGuest } from '@/irc/client.js';
import { useMainStore } from "@/mainStore";
import { onClickOutside } from '@vueuse/core'
import { GifIcon, FaceSmileIcon, UserIcon, VideoCameraIcon, CameraIcon, XMarkIcon, SpeakerWaveIcon, NoSymbolIcon } from '@heroicons/vue/24/solid'
import NavButton from './NavButton.vue';
import EmojiPicker from 'vue3-emoji-picker';
import { startBroadcasting } from '@/irc/broadcaster.js'
import 'vue3-emoji-picker/css';
import { confirm } from '@zapadale/vue3-confirm-dialog'
import { convertStringWithEmoji, isModUser } from "@/helpers"
import { commands, MSG_TYPE } from '@/constants';
import CommandPicker from './CommandPicker.vue';

const store = useMainStore();

const emojiPickerModal = ref(null);
onClickOutside(emojiPickerModal, store.hideEmojiSelector);

const broadcastOptionsModal = ref(null);
onClickOutside(broadcastOptionsModal, store.hideBroadcastOption);

const formData = ref(new FormData());
const isSending = ref(false);

const handleChangeText = (e) => {
    store.messageValue = convertStringWithEmoji(e.target.value);
}

const onSendMessage = () => {
    const messageWithStyle = {
        room: store.activeRoom,
        message: store.messageValue,
        css: store.BackgroundColor + " " + store.textColor + " " + store.messageStyleBold + " " + store.messageStyleUnderline + " " + store.messageStyleItalic,
    }
    const splitMessage = messageWithStyle.message.split(' ')
    const isMod = isModUser(store)
    const isGuest = store.userName.startsWith("G|")
    const command = commands.find((cmd) => ((cmd.command === splitMessage[0] || cmd.alias === splitMessage[0]) && (!cmd.moderator || isMod) && (!cmd.guest || isGuest)))

    if (command && splitMessage.length >= command.unit) {
        const secondParam = splitMessage?.[1];
        splitMessage.splice(0, command.unit - 1);
        let messageString = splitMessage.join(" ");
        let room;

        switch (command.type) {
            case MSG_TYPE.ACTION:
                sendAction(messageString, store.activeRoom, store.userName);
                break;
            case MSG_TYPE.JOIN:
                room = store.roomList.find((room) => room.name.toLowerCase() === secondParam.toLowerCase())
                if (!room)
                    break;
                join(room.name)
                store.showMessageRoom();
                store.setActiveRoom(room.name);
                
                store.storeActiveRoomList({
                    name: room.name,
                    count: room.count,
                    topic: room.topic,
                    type: "room"
                });
                store.tabNotification[room.name] = [];
                setTimeout(() => {
                    store.tabNotification[room.name] = [];
                }, 1000);
                store.hideWhisperInput()
                break;
            case MSG_TYPE.PART:
                room = store.roomList.find((_room) => _room.name.toLowerCase() === secondParam.toLowerCase())
                if (room) {
                    store.removeRoomList(room)
                    if (store.activeRoomList[0]) {
                        join(store.activeRoomList[0].name);
                    } else {
                        store.showRoomList();
                        store.setActiveRoom('')
                    }
                    quit(room.name);
                }
                break;                
            case MSG_TYPE.QUIT:
                logout(store.userName);
                break;
            case MSG_TYPE.KICK:
                sendKick(store.activeRoom, secondParam, messageString)
                break;
            case MSG_TYPE.BAN:
                store.addToBanList(secondParam, store.activeRoom)
                sendWhois(secondParam)
                break;
            case MSG_TYPE.UNBAN:
                sendUnBan(store.activeRoom, secondParam)
                break;
            case MSG_TYPE.NOTICE:
                sendNotice(messageString, secondParam);
            break;
            case MSG_TYPE.WHISPER:
                sendWhisper(messageString, secondParam);
            break;
            case MSG_TYPE.NICK:
                sendNicknameForGuest(secondParam);
            break;
        }        
    } else {
        store.socket.emit('storeStyle', messageWithStyle)
        store.socket.on('messageStyle', (message) => {
            store.storeSocketMessage(message);
        })
        sendMessage(MSG_TYPE.MESSAGE, store.messageValue, store.activeRoom);
    }
    store.messageValue = "";
}

const onSelectEmoji = (emoji) => {
    const startIdx = messageInputRef.value.selectionStart;
    store.messageValue = `${store.messageValue.substring(0, startIdx)}${emoji.i}${store.messageValue.substring(startIdx)}`;
    store.toggleEmojiSelector();
    messageInputRef.value.focus();
}

const importFile = () => {
    const imgInput = document.getElementById('imgFile');
    imgInput.value = '';

    imgInput.click();

    imgInput.onchange = () => {
        const files = imgInput.files;
        if (files.length > 0) {
            const file = files[0];
            formData.value.set('image', file); // 'image' is the field name expected by the server
            formData.value.set('name', store.userName);

            const imageUrl = URL.createObjectURL(file);
            store.setImageUrl(imageUrl);
            store.showimageSender();
        }
    }
}

const sendImage = async () => {
    if (formData.value.has('image')) {
        isSending.value = true; // Start sending, disable the button
        try {
            const response = await axios.post(`${store.config.apiHost}/api/upload_image`, formData.value, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200 && response.data.path) {
                sendMessage(MSG_TYPE.GIF, response.data.path, store.activeRoom);
                store.setImageUrl(response.data.path); // Update the image URL with the path from the response
                formData.value.delete('image'); // Clear the formData
                formData.value.delete('name'); 
            } else {
                console.error('Failed to upload image');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        } finally {
            isSending.value = false; // Finish sending, enable the button
        }
    }

    hideImageSender();
}

const hideImageSender = () => {
    store.hideimageSender();
}

const handleCtrlUp = (event) => {
    event.preventDefault();
    
    console.log("Ctrl + Up Arrow pressed");
};

const handleCtrlDown = (event) => {
    event.preventDefault();

    store.messageValue = '';
}

const handleCtrlLeft = (event) => {
    event.preventDefault();

    let currentIndex = store.activeRoomList.findIndex(room => room.name === store.activeRoom);
    let leftRoomIndex = (currentIndex - 1 + store.activeRoomList.length) % store.activeRoomList.length;
    joinRoom(store.activeRoomList[leftRoomIndex].name);
}

const handleCtrlRight = (event) => {
    event.preventDefault();

    let currentIndex = store.activeRoomList.findIndex(room => room.name === store.activeRoom);
    let leftRoomIndex = (currentIndex + 1) % store.activeRoomList.length;
    joinRoom(store.activeRoomList[leftRoomIndex].name);
}

const handleBackspace = () => {
    // event.preventDefault();

    if(store.messageValue === '') store.storeRecipient('');
}

const joinRoom = (room) => {
    join(room);
    store.hideRoomList();
    store.showMessageRoom();
    store.setActiveRoom(room);
    store.storeRoomName(room);
    store.tabNotification[room] = [];
    store.hideWhisperInput()
}

const setupBroadCasting = (option) => {
    store.hideBroadcastOption();
    confirm(
        {
          title: 'Broadcasting Privacy',
          message: 'Do you want to broadcast privately?',
          disableKeys: false,
          auth: false,
          button: {
            yes: 'Yes',
            no: 'No'
          },
          callback: confirm => {
            if (confirm) {
                store.enablePrivateBroadcasting();
            }
            else {
                store.enablePublicBroadcasting();
            }
            startBroadcasting(option);
          }
        }
    )
}

const handleChooseCommand = (command) => {
    store.messageValue = command.command  
    store.hideCommandPicker();
    messageInputRef.value.focus();
}
const handleTabComplete = (event) => {
  // Prevent the default Tab behavior (like focusing the next element)
  event.preventDefault();

  // Get the input element and current message text
  const input = messageInputRef.value;
  const text = store.messageValue;
  const cursorPosition = input.selectionStart;
  
  // Get the text up to the current cursor position
  const textUpToCursor = text.slice(0, cursorPosition);
  // Split the text by whitespace to get the current word being typed
  const words = textUpToCursor.split(/\s+/);
  const currentWord = words[words.length - 1];
  
  if (!currentWord) return;
  
  // Get the user list for the active room (adjust based on where your user list is stored)
  const users = store.sidebarUserList[store.activeRoom] || [];
  
  // Find a user whose nickname starts with the typed partial (ignoring any special prefixes)
  const match = users.find(user => {
    // Remove any prefix like '@' or '+' for matching
    const username = user.userName.replace(/^[@+]/, "");
    return username.toLowerCase().startsWith(currentWord.toLowerCase());
  });
  
  if (match) {
    // Use the full user name (you can choose whether to include the prefix or not)
    const completedName = match.userName;
    // Replace the current word with the completed nickname and add a trailing space
    words[words.length - 1] = completedName;
    const newText = words.join(" ") + " ";
    store.messageValue = newText;
    
    // Set the caret position to the end of the updated text
    input.setSelectionRange(newText.length, newText.length);
  }
};

let messageInputRef = ref();
let image = ref("");
</script>
